export function formatSizeOptions(optionConfigs) {
  let optionsFormatted = [];

  for (var i = 0; optionConfigs && i < optionConfigs.length - 1; i++) {
    const firstValue = optionConfigs[i];
    const secondValue = optionConfigs[i + 1];
    optionsFormatted.push(`${firstValue} - ${secondValue}`);
  }
  return optionsFormatted;
}

export function getSavePercentage(price, salePrice) {
  const percent = Math.round((price - salePrice) / price * 100);
  return `${percent}%`;
}