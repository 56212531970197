import moment from 'moment';

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      return resolve(reader.result);
    };
    reader.onerror = function (error) {
      return reject(error);
    };
  });
}

export function chooseRandomFromArray(myArray) {
  return myArray[Math.floor(Math.random() * myArray.length)];
}

export function getUUID() {
  var uid = String((Date.now() + Math.random()) * 10000);
  return parseInt(uid.substr(5, uid.length));
}

export function arrayOfNumbers(length = 30, includeZero = false) {
  return Array(includeZero ? length : length + 1).fill(0).map((_, num) => includeZero ? num : num + 1);
}

export function getCurrentPage(pathname) {
  if (typeof window !== 'undefined') {
    if (pathname) {
      return window.location.pathname || '';
    } else {
      return window.location.href || '';
    }
  }
  return '';
}

export function getPageHash() {
  if (typeof window !== 'undefined') {
    return window.location.hash.split('#')[1];
  }
  return '';
}

export function isNewListing(date) {
  return moment().diff(moment(date), 'days') < 3;
}