import { getDocument } from '../helpers/Firebase';
import * as types from './actionTypes';

export function loadOrders() {
  return async (dispatch, getState) => {
    const { accountData } = getState().userReducer;
    const { orders: orderNumbers } = accountData || {};
    let promises = [];

    for (var i = 0; i < orderNumbers.length; i++) {
      promises.push(getDocument(`orders/${orderNumbers[i]}`));
    }

    const orders = await Promise.all(promises);

    dispatch({
      type: types.GET_ORDERS,
      orders,
    });
  };
}