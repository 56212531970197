/* globals window */
import {
  createStore, applyMiddleware, compose, combineReducers,
} from 'redux';
import { createLogger } from 'redux-logger';

import thunk from 'redux-thunk';
import * as reducers from './reducers/';

const enhancers = [];
const middleware = [thunk];

if (typeof window !== 'undefined') {
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }

  const loggerOptions = { duration: true };

  if (!window.location.origin.match(/localhost/gi)) {
    loggerOptions.stateTransformer = state => JSON.stringify(state, null, 2);
    loggerOptions.actionTransformer = action => JSON.stringify(action, null, 2);
  }
  middleware.push(createLogger(loggerOptions));
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);

const appReducer = combineReducers({ ...reducers });

const store = createStore(appReducer, composedEnhancers);

export default store;
