import { navigate } from '@reach/router';
import * as firebaseHelpers from '../helpers/Firebase';
import * as types from './actionTypes';
import { setLoading } from './loadingActions';
import { loadUserData, getAccountType } from './userActions';
const securePageMatch = /dashboard$/;

export function watchFirebaseAuthState() {
  return (dispatch) => {
    dispatch({ type: types.LOGGING_IN });
    return new Promise((resolve) => {
      firebaseHelpers.authStateChanged((user) => {
        dispatch({ type: types.LOGIN, user });
        dispatch(setLoading(true));

        if ((!user || (user && user.isAnonymous)) && window.location.pathname.match(securePageMatch)) {
          dispatch(setLoading(false));
          return navigate('/login');
        } else if (user) {
          dispatch(getAccountType()).then(() => {
            dispatch(loadUserData(user));
            dispatch(setLoading(false));
          });
        } else {
          dispatch(setLoading(false));
        }
        resolve();
      });
    });
  };
}