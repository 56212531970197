import { makeRequest, API_URL } from '../Fetch';

export function createPurchase({
  listings, user, amount, rateId, shipmentId, address, paypalID, stripeToken,
}) {
  const payload = {
    user,
    stripeToken,
    paypalID,
    listings,
    address,
    amount,
    shipmentId,
    rateId,
  };
  return makeRequest(API_URL + 'purchase', payload).send();
}