import { getAuthToken } from '../Firebase';

export const API_URL = process.env.GATSBY_API_URL;

export function getPageIdFromUrl(url) {
  const urlParams = new URL(url).searchParams;
  const page = urlParams.get('p');

  if (!page) {
    return new URL(url).pathname.slice(1);
  } else {
    return page;
  }
}

export function makeRequest(url, data) {
  let method, setNoAuth, headers = {};
  let error = false;

  if (!data) {
    method = 'GET';
  } else {
    method = 'POST';
  }

  var self = {
    send: () => new Promise(async (resolve, reject) => {
      let authToken;

      if (!setNoAuth) {
        authToken = await getAuthToken();
        headers['Authorization'] = `Bearer ${authToken}`;
      }

      var option = {
        method: method,
        mode: 'cors',
        headers: headers,
      };

      if (method === 'GET' && data) {
        url += '?' + getQueryString(data);
      } else if (method === 'PUT' || method === 'POST' || method === 'PATCH' || (method === 'DELETE' && data)) {
        if (data) {
          option.body = JSON.stringify(data);
          headers['Content-Type'] = 'application/json';
        }
      }
      fetch(url, option).then((response) => {
        if (response.status >= 400) {
          error = true;
        }
        return response.text();
      }).then((body) => {
        let result = body;

        try {
          result = JSON.parse(body);
        } catch (e) {
          //
        }

        if (error) {
          return reject(result);
        }
        return resolve(result);
      })
        .catch((err) => reject(err));
    }),
    addHeader: (key, value) => {
      if (value) {
        headers[key] = value;
      }
      return self;
    },
    setNoAuth: () => {
      setNoAuth = true;
      return self;
    },
    setAuth: (username, password = '') => {
      headers['Authorization'] = 'Basic ' + btoa(`${username}:${password}`);
      return self;
    },
    setMethod: (type) => {
      method = type;
      return self;
    },
  };
  return self;
}

export function getQueryString(query) {
  let esc = encodeURIComponent;
  let queryString = Object.keys(query)
    .map(k => esc(k) + '=' + esc(query[k]))
    .join('&');
  return queryString;
}