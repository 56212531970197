// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-prismicio-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../../../node_modules/@prismicio/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-prismicio-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-checkout-js": () => import("./../../../src/templates/checkout.js" /* webpackChunkName: "component---src-templates-checkout-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-copyright-js": () => import("./../../../src/templates/copyright.js" /* webpackChunkName: "component---src-templates-copyright-js" */),
  "component---src-templates-create-account-js": () => import("./../../../src/templates/create-account.js" /* webpackChunkName: "component---src-templates-create-account-js" */),
  "component---src-templates-create-account-seller-js": () => import("./../../../src/templates/create-account-seller.js" /* webpackChunkName: "component---src-templates-create-account-seller-js" */),
  "component---src-templates-dashboard-js": () => import("./../../../src/templates/dashboard.js" /* webpackChunkName: "component---src-templates-dashboard-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-how-it-works-js": () => import("./../../../src/templates/how-it-works.js" /* webpackChunkName: "component---src-templates-how-it-works-js" */),
  "component---src-templates-item-js": () => import("./../../../src/templates/item.js" /* webpackChunkName: "component---src-templates-item-js" */),
  "component---src-templates-login-js": () => import("./../../../src/templates/login.js" /* webpackChunkName: "component---src-templates-login-js" */),
  "component---src-templates-media-js": () => import("./../../../src/templates/media.js" /* webpackChunkName: "component---src-templates-media-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-profile-js": () => import("./../../../src/templates/profile.js" /* webpackChunkName: "component---src-templates-profile-js" */),
  "component---src-templates-reset-password-js": () => import("./../../../src/templates/reset-password.js" /* webpackChunkName: "component---src-templates-reset-password-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */),
  "component---src-templates-terms-of-service-js": () => import("./../../../src/templates/terms-of-service.js" /* webpackChunkName: "component---src-templates-terms-of-service-js" */)
}

