export function removeStorage(name) {
  try {
    localStorage.removeItem(name);
    localStorage.removeItem(name + '_expiresIn');
  } catch (e) {
    console.log('removeStorage: Error removing key [' + name + '] from localStorage: ' + JSON.stringify(e));
    return false;
  }
  return true;
}

export function getStorage(key) {
  var now = Date.now();
  var expiresIn = localStorage.getItem(key + '_expiresIn');

  if (!expiresIn) {
    expiresIn = 0;
  }

  if (expiresIn < now) {
    removeStorage(key);
    return null;
  } else {
    try {
      var value = localStorage.getItem(key);

      try {
        value = JSON.parse(value);
      } catch {
        //
      }
      return value;
    } catch (e) {
      console.log('getStorage: Error reading key [' + key + '] from localStorage: ' + JSON.stringify(e));
      return null;
    }
  }
}
export function setStorage(key, value, expires) {
  if (!expires) {
    expires = (24 * 60 * 60);
  } else {
    expires = Math.abs(expires);
  }

  var now = Date.now();
  var schedule = now + expires * 1000;

  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + '_expiresIn', schedule);
  } catch (e) {
    console.log('setStorage: Error setting key [' + key + '] in localStorage: ' + JSON.stringify(e));
    return false;
  }
  return true;
}