import * as firebaseHelpers from '../helpers/Firebase';
import { formatAmount } from '../helpers/Format';
import * as types from './actionTypes';

export function loadOptionConfigs() {
  return dispatch => {
    firebaseHelpers.watchPropertyFromDatabase('config', (optionConfigs) => {
      dispatch({
        type: types.SET_CONFIGS,
        optionConfigs,
      });
    });
  };
}

export function loadProducts(oneTime) {
  return dispatch => new Promise((resolve) => {
    firebaseHelpers.watchCollectionChanges('listings', (listings) => {
      let products = {};
      let productsListed = 0;
      let productsSold = 0;

      listings.forEach((data) => {
        productsListed++;
        productsSold += data.sold ? parseInt(data.salePrice) : 0;

        if (!data.sold) {
          products[data.__id] = data;
          const [bustSize, waistSize, hipSize] = data.dimensions;
          products[data.__id].bustSize = bustSize;
          products[data.__id].waistSize = waistSize;
          products[data.__id].hipSize = hipSize;
        }
      });

      dispatch({
        type: types.GET_PRODUCTS,
        products,
        productsSold: formatAmount(productsSold),
        productsListed,
      });
      resolve();
    }, oneTime);
  });
}