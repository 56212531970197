/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import jquery from 'jquery';
import { setConfig } from 'react-hot-loader';
import './src/modules/jquery-flexdatalist-2.2.4/jquery.flexdatalist.css';

export default () => {
  if (!window.$) {
    window.$ = jquery;
    window.jQuery = jquery;
    require('./src/modules/jquery-flexdatalist-2.2.4/jquery.flexdatalist.js');
  }
  setConfig({
    // set this flag to support SFC if patch is not landed
    pureSFC: true,
  });

  if (process.env.GATSBY_HEAP_ID) {
    (function () {
      (window.heap = window.heap || []),
      (heap.load = function (e, t) {
        (window.heap.appid = e), (window.heap.config = t = t || {});
        var r = t.forceSSL || 'https:' === document.location.protocol,
          a = document.createElement('script');

        (a.type = 'text/javascript'),
        (a.async = !0),
        (a.src =
              (r ? 'https:' : 'http:') +
              '//cdn.heapanalytics.com/js/heap-' +
              e +
              '.js');

        var n = document.getElementsByTagName('script')[0];
        n.parentNode.insertBefore(a, n);

        for (
          var o = function (e) {
              return function () {
                heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
              };
            },
            p = [
              'addEventProperties',
              'addUserProperties',
              'clearEventProperties',
              'identify',
              'resetIdentity',
              'removeEventProperty',
              'setEventProperties',
              'track',
              'unsetEventProperty',
            ],
            c = 0;
          c < p.length;
          c++
        ) {
          heap[p[c]] = o(p[c]);
        }
      });
      heap.load(process.env.GATSBY_HEAP_ID);
    })();
  }
  Number.prototype.round = function (places) {
    return +(Math.round(this + 'e+' + places) + 'e-' + places);
  };
};