//product
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const SET_CONFIGS = 'SET_CONFIGS';
//shop
export const SET_TAG_SIZE_FILTER = 'SET_TAG_SIZE_FILTER';
export const SET_BOUTIQUE_FILTER = 'SET_BOUTIQUE_FILTER';
export const SET_STREET_SIZE_FILTER = 'SET_STREET_SIZE_FILTER';
export const SET_PRICE_RANGE = 'SET_PRICE_RANGE';
export const SET_SERACH_FILTER = 'SET_SERACH_FILTER';
export const SET_STYLE_FILTER = 'SET_STYLE_FILTER';
export const SHOP_STYLE_VALUES = 'SHOP_STYLE_VALUES';
export const SET_SORT_BY_FILTER = 'SET_SORT_BY_FILTER';
export const SET_CATEGORY_LABEL_OPTIONS = 'SET_CATEGORY_LABEL_OPTIONS';
//checkout
export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const EDIT_CART_ITEM = 'EDIT_CART_ITEM';
export const SET_CART_ITEMS = 'SET_CART_ITEMS';
export const SET_SHIPPING_DETAILS = 'SET_SHIPPING_DETAILS';
export const CLEAR_SHIPPING_DETAILS = 'CLEAR_SHIPPING_DETAILS';
//user
export const LOGIN = 'LOGIN';
export const LOGGING_IN = 'LOGGING_IN';
export const LOAD_ACCOUNT_DATA = 'LOAD_ACCOUNT_DATA';
export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export const SET_ACCOUNT_TYPE = 'SET_ACCOUNT_TYPE';
export const GET_ORDERS = 'GET_ORDERS';
export const LOAD_QUESTIONS = 'LOAD_QUESTIONS';
export const LOAD_OFFERS = 'LOAD_OFFERS';
//loading
export const LOADING = 'LOADING';
//home
export const SET_RECENTLY_ADDDED_FILTER = 'SET_RECENTLY_ADDDED_FILTER';
export const SET_META_TAGS = 'SET_META_TAGS';
//Modal
export const SHOW_MODAL = 'SHOW_MODAL';
//notifications
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
