import React from 'react';
import Row from '../Row';
import Button1 from '../Button1';


function PurchaseFromOffer({
  addItemToCart, listing, id,
}) {
  return (
    <Row style={{ marginTop: 20 }}>
      <Button1 onClick={() => addItemToCart(listing, id)} style={{ height: 44, marginTop: 20 }} color="#7DBEC8">PURCHASE DRESS</Button1>
    </Row>
  );
}

export default PurchaseFromOffer;
