import * as types from '../actions/actionTypes';

const initialState = {
  user: null,
  accountData: null,
  messages: [],
  accountType: null,
  orders: [],
  questions: [],
  offers: [],
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ORDERS: {
      return {
        ...state,
        orders: action.orders,
      };
    }
    case types.SET_ACCOUNT_TYPE: {
      return {
        ...state,
        accountType: action.accountType,
      };
    }
    case types.LOAD_MESSAGES: {
      return Object.assign({}, state, { messages: action.messages });
    }
    case types.LOAD_QUESTIONS: {
      return Object.assign({}, state, { questions: action.questions });
    }
    case types.LOAD_OFFERS: {
      return Object.assign({}, state, { offers: action.offers });
    }
    case types.LOAD_ACCOUNT_DATA: {
      return {
        ...state,
        accountData: action.accountData,
      };
    }
    case types.LOGIN: {
      return {
        ...state,
        user: action.user,
      };
    }
    default:
      return state;
  }
};