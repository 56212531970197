import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
display: flex;
`;

function Row({ children, ...props }) {
  return (
    <Container {...props}>
      {children}
    </Container>
  );
}

export default Row;