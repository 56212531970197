var defaultConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
};

export function getFirebase(config = defaultConfig) {
  if (typeof window !== 'undefined') {
    let firebase = require('firebase/app').default;
    require('firebase/auth');
    require('firebase/firestore');
    require('firebase/database');

    if (!firebase.apps.length) {
      firebase.initializeApp(config);

      if (window.Cypress) {
        firebase.firestore().settings({ experimentalForceLongPolling: true });
      }
      firebase.firestore().enablePersistence({ synchronizeTabs:true }).catch((e) => {
        console.warn('Could not enable firestore persistence', e);
      });
    }
    return firebase;
  } else {
    return null;
  }
}