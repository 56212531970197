import * as types from '../actions/actionTypes';

const initialState = { notifications: [] };

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_NOTIFICATION: {
      let _notifications = state.notifications.slice(0);
      const found = _notifications.find(({ id }) => action.id === id);

      if (!found) {
        _notifications = [
          ...state.notifications,
          {
            component: action.component,
            callback: action.callback,
            title: action.title,
            subTitle: action.subTitle,
            id: action.id,
          },
        ];
      }
      return {
        ...state,
        notifications: _notifications,
      };
    }
    case types.REMOVE_NOTIFICATION: {
      let _notifications = state.notifications.slice(0);
      const indexToRemove = _notifications.indexOf(action.id);

      if (indexToRemove >= 0){
        _notifications = _notifications.splice(indexToRemove);
      }
      return {
        ...state,
        notifications: _notifications,
      };
    }
    default:
      return state;
  }
};