import * as types from '../actions/actionTypes';

const initialState = { recentlyAddedFilter: null, metaTags: { } };

export const homeReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.SET_RECENTLY_ADDDED_FILTER: {
    return {
      ...state,
      recentlyAddedFilter: action.recentlyAddedFilter,
    };
  }
  case types.SET_META_TAGS: {
    return {
      ...state,
      metaTags: action.metaTags,
    };
  }
  default:
    return state;
  }
};