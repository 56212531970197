import * as types from '../actions/actionTypes';

const initialState = { loading: true };

export const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.LOADING: {
    return {
      ...state,
      loading: action.loading,
    };
  }
  default:
    return state;
  }
};