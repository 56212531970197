import { navigate } from '@reach/router';
import { favoriteListing } from '../helpers/API';
import * as types from './actionTypes';

export function setRecentlyAddedFilter(recentlyAddedFilter) {
  return {
    type: types.SET_RECENTLY_ADDDED_FILTER,
    recentlyAddedFilter,
  };
}

export function handleRedirect(params) {
  switch (params.type) {
  case 'like_dress': {
    favoriteListing(params.dress_id);
    navigate(`/item/${params.dress_id}`);
    break;
  }
  case 'buy_dress': {
    navigate('checkout');
    break;
  }
  default:
    break;
  }
}

export function setMetaTags({
  description, image, url, title,
}) {
  return {
    type: types.SET_META_TAGS,
    metaTags: {
      description, image, url, title,
    },
  };
}