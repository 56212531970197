module.exports = [{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en-us","path":"/preview","previews":true,"repositoryName":"sobridely","accessToken":"MC5YeUxfbUJJQUFDQUFua0tn.77-9fu-_vR8TU--_vQDvv71-77-9e3Hvv71nfQzvv73vv71WazPvv73vv702Yu-_vU_vv73vv73vv71a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
