import * as types from '../actions/actionTypes';
import { setStorage } from '../helpers/LocalStorage';
import { SLIDER_MAX } from '../constants';
const MENU_ITEMS = ['designer', 'material', 'neckline', 'sleeve', 'silhouette'];
const initialState = {
  productsListed: null,
  productsSold: null,
  products: {},
  optionConfigs: {},
  checkoutItems: {},
  priceRangeLowerBound: 0,
  priceRangeUpperBound: SLIDER_MAX,
  tagSizeFilter: null,
  boutiqueFilter: null,
  streetSizeFilter: null,
  shopSearchValue: null,
  styleKeyFilters: {},
  shopStyleValues: {
    designer: [], material: [], neckline: [], sleeve: [], silhouette: [],
  },
  sortByFilter: 'date',
  shippingCost: null,
  shipmentId: null,
  rateId: null,
};

export const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SHIPPING_DETAILS: {
      return {
        ...state,
        shippingCost: action.shippingCost || state.shippingCost,
        shipmentId: action.shipmentId || state.shipmentId,
        rateId: action.rateId || state.rateId,
      };
    }
    case types.CLEAR_SHIPPING_DETAILS: {
      return {
        ...state,
        shippingCost: null,
        shipmentId: null,
        rateId: null,
      };
    }
    case types.SET_CART_ITEMS: {
      return {
        ...state,
        checkoutItems: action.checkoutItems || {},
      };
    }
    case types.ADD_ITEM_TO_CART: {
      const copy = { ...state.checkoutItems };

      if (copy[action.itemId]) {
        copy[action.itemId] = copy[action.itemId] + 1;
      } else {
        copy[action.itemId] = 1;
      }
      setStorage('checkoutItems', JSON.stringify(copy));
      return {
        ...state,
        checkoutItems: copy,
      };
    }
    case types.EDIT_CART_ITEM: {
      const copy = { ...state.checkoutItems };

      if (action.quantity === 0) {
        delete copy[action.itemId];
      } else {
        copy[action.itemId] = action.quantity;
      }
      setStorage('checkoutItems', JSON.stringify(copy));
      return {
        ...state,
        checkoutItems: copy,
      };
    }
    case types.SET_SORT_BY_FILTER: {
      return {
        ...state,
        sortByFilter: action.sortByFilter,
      };
    }
    case types.SET_STYLE_FILTER: {
      let copy = { ...state.styleKeyFilters };
      const { styleFilterKey: key, styleFilterValue: value } = action;
      const exists = copy[key] && copy[key].includes(value);

      if (!key && !value) {
        copy = {};
      } else if (exists) {
      //need to remove
        const index = copy[key].indexOf(value);
        copy[key].splice(index, 1);

        if (!copy[key].length) {
        //array is empty need to remove
          delete copy[key];
        }
      } else {
      //need to add to array
        if (!copy[key]) {
          copy[key] = [];
        }
        copy[key].push(value);
      }
      return {
        ...state,
        styleKeyFilters: copy,
      };
    }
    case types.SET_SERACH_FILTER: {
      return {
        ...state,
        shopSearchValue: action.shopSearchValue,
      };
    }
    case types.SET_TAG_SIZE_FILTER: {
      return {
        ...state,
        tagSizeFilter: action.tagSizeFilter,
      };
    }
    case types.SET_BOUTIQUE_FILTER: {
      return {
        ...state,
        boutiqueFilter: action.boutiqueFilter,
      };
    }
    case types.SET_STREET_SIZE_FILTER: {
      return {
        ...state,
        streetSizeFilter: action.streetSizeFilter,
      };
    }
    case types.SET_PRICE_RANGE: {
      return {
        ...state,
        priceRangeLowerBound: action.priceRangeLowerBound,
        priceRangeUpperBound: action.priceRangeUpperBound,
      };
    }
    case types.SET_CONFIGS: {
      return {
        ...state,
        optionConfigs: action.optionConfigs,
      };
    }
    case types.GET_PRODUCTS: {
      const newProducts = { ...action.products || {} };
      const shopStyleValues = {};

      if (Object.keys(newProducts).length) {
        Object.keys(newProducts).forEach((productId) => {
          MENU_ITEMS.forEach((label) => {
            if (!shopStyleValues[label]) {
              shopStyleValues[label] = [];
            }

            if (!shopStyleValues[label].includes(newProducts[productId][label])) {
              shopStyleValues[label].push(newProducts[productId][label]);
            }
          });
        });

        Object.keys(initialState.shopStyleValues).forEach((shopConfigKey) =>{
          if (shopStyleValues[shopConfigKey]) {
            shopStyleValues[shopConfigKey].push(...initialState.shopStyleValues[shopConfigKey]);
          }
          shopStyleValues[shopConfigKey] = shopStyleValues[shopConfigKey].filter((item, index) => shopStyleValues[shopConfigKey].indexOf(item) === index);
        });
      }
      return {
        ...state,
        products: { ...newProducts },
        shopStyleValues,
        productsListed: action.productsListed,
        productsSold: action.productsSold,
      };
    }
    default:
      return state;
  }
};