/* global heap */
import { navigate } from '@reach/router';
import qs from 'qs';
import * as firebaseHelpers from '../helpers/Firebase';
import { makeRequest, API_URL } from '../helpers/Fetch';
import * as apiHelpers from '../helpers/API';
import * as userHelpers from '../helpers/User';
import * as types from './actionTypes';
import { setLoading } from './loadingActions';
import { loadMessages } from './messagesActions';
import { handleRedirect } from './homeActions';
import { loadOrders } from './ordersActions';
import {
  loadQuestions, loadNotifications, loadOffers,
} from './notificationsActions';
import { loadProducts } from './productActions';

export function acceptOffer(listing, offerId) {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      await apiHelpers.updateOffer(listing, offerId, 'accepted');
      const { user } = getState().userReducer;
      await dispatch(loadProducts(true));
      await dispatch(loadUserData(user));
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

export function declineOffer(listing, offerId) {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      await apiHelpers.updateOffer(listing, offerId, 'declined');
      const { user } = getState().userReducer;
      await dispatch(loadProducts(true));
      await dispatch(loadUserData(user));
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

export function loadUserData(user) {
  return async (dispatch, getState) => {
    const { accountType } = getState().userReducer;

    if (accountType === 0 || accountType === 1) {
      await dispatch(loadAccountData(user, 'sellers'));
    } else if (accountType === 2) {
      await dispatch(loadAccountData(user, 'users'));
    }
  };
}

export function getAccountType() {
  return async (dispatch) => {
    let accountType = 2;

    try {
      accountType = await apiHelpers.getAccountType();
    } catch {
      //
    }
    dispatch(setAccountType(accountType));
  };
}

export function setAccountType(accountType) {
  return {
    type: types.SET_ACCOUNT_TYPE,
    accountType,
  };
}

export function loadAccountData({ uid }, path) {
  return dispatch => new Promise((resolve) => {
    firebaseHelpers.watchDocumentChanges(`${path}/${uid}`, async (accountData) => {
      dispatch({
        type: types.LOAD_ACCOUNT_DATA,
        accountData,
      });
      dispatch(loadOrders());



      if (path === 'sellers') {
        dispatch(loadQuestions());
      }
      await dispatch(loadOffers(uid, accountData));
      await dispatch(loadMessages(uid));
      dispatch(loadNotifications());
      resolve();
    });
  });
}

export function createSellerAccount(form) {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      await firebaseHelpers.signOut();
      const res = await apiHelpers.createSeller(form);

      if (res) {
        await navigate('/dashboard#my-listings');
      }
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

export function createBuyerAccount(form) {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      await firebaseHelpers.signOut();
      await apiHelpers.createUser(form);
      navigate('/dashboard');
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

export function updateAccountData(form) {
  return async (dispatch, getState) => {
    const { accountType } = getState().userReducer;
    const path = accountType === 2 ? 'user' : 'seller';

    try {
      dispatch(setLoading(true));
      await makeRequest(API_URL + path, form)
        .setMethod('PUT')
        .send();
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

export function updatePassword({ newPassword, newPasswordConfirm }) {
  return async dispatch => {
    try {
      if (!newPasswordConfirm || !newPassword) {
        return alert('You fill out the password fields to continue');
      }

      if (!newPasswordConfirm !== newPassword) {
        return alert('The given passwords do not match. Please check they are the same.');
      }
      dispatch(setLoading(true));
      // await firebaseHelpers.reauthenticate(oldPassword);
      await userHelpers.updatePassword(newPassword);
    } catch (e) {
      console.log(e);

      if (e.code === 'auth/wrong-password') {
        return alert('Invalid password');
      }
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

export function resetEmail(email) {
  return async dispatch => {
    try {
      if (email) {
        dispatch(setLoading(true));
        await firebaseHelpers.sendEmailResetLink(email);
      } else {
        return alert('You must enter an email address first');
      }
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

export function login({ email, password }) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const { user } = await firebaseHelpers.signInWithEmailAndPassword(email, password);

      if (user && !user.isAnonymous) {
        heap.identify(user.uid);
        const urlRedirectObject = qs.parse(window.location.search.replace(/\?/ig, ''));

        if (urlRedirectObject.redirect == 'true') {
          handleRedirect(urlRedirectObject);
        } else {
          navigate('/dashboard');
        }
      }
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

export function editListing(listingId, form) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      await apiHelpers.editListing(listingId, form);
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

export function boostListing(listingId) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      await apiHelpers.boostListing(listingId);
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

export function publishListings(listings) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      await apiHelpers.publishListings(listings);
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

export function createNewListing(form) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      await apiHelpers.createNewListing(form);
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

export function updatePackage(packageType) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      await apiHelpers.updatePackage(packageType);
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

export function answerQuestion(listingId, questionId, response) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      await apiHelpers.answerQuestion(listingId, questionId, response);
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

export function deleteSeller() {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      await apiHelpers.deleteSeller();
      navigate('/login');
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

export function deleteListing(listingId) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      await apiHelpers.deleteListing(listingId);
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}