import styled from 'styled-components';

export const Container = styled.button`
  background: ${props => props.color};
  opacity: ${props => props.disabled ? .5 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  height: 60px;
  width: 226px;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 1px;
  color: #FFFFFF;
  text-align: center;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  text-transform: uppercase;
  margin: auto;
`;