import React from 'react';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import store from './src/store';
import { getFirebase } from './src/modules/firebase';
import { setCartItems } from './src/actions/checkoutActions';
import { getStorage } from './src/helpers/LocalStorage';
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK);

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  if (typeof window !== undefined) {
    const firebaseActions = require('./src/actions/firebaseActions');
    const productActions = require('./src/actions/productActions');
    const firebase = getFirebase();

    if (firebase) {
      store.dispatch(productActions.loadProducts());
      store.dispatch(productActions.loadOptionConfigs());
      store.dispatch(firebaseActions.watchFirebaseAuthState());
      const persistedCheckoutItems = getStorage('checkoutItems');
      store.dispatch(setCartItems(persistedCheckoutItems));
    }
  }
  return (
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        {element}
      </Elements>
    </Provider>
  );
};
