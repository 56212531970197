import { makeRequest, API_URL } from '../Fetch';
import * as firebaseHelpers from '../Firebase';
import createSellerPayload from '../../payloads/seller/POST';


export async function createSeller(form) {
  let payload = createSellerPayload(form, form.accountType === 0);
  const url = form.accountType === 0 ? 'seller/boutique' : 'seller';
  const loginToken = await makeRequest(API_URL + url, payload)
    .setMethod('POST')
    .setNoAuth()
    .send();

  if (loginToken) {
    try {
      await firebaseHelpers.signInWithToken(loginToken);
    } catch {
      return false;
    }
    return true;
  } else {
    return false;
  }
}

export function deleteSeller() {
  return makeRequest(`${API_URL}seller`)
    .setMethod('DELETE')
    .send();
}

export function createNewListing(data) {
  const payload = {
    originalOwner: data.originalOwner,
    hasReceipt: data.hasReceipt,
    accessories: data.accessories?.trim(),
    alterations: data.alterations?.trim(),
    color: data.color?.trim(),
    condition: data.condition?.trim(),
    story: data.story?.trim(),
    history: data.history?.trim(),
    receipt: data.receipt?.trim(),
    designer: data.designer,
    dimensions: [data.bustSize, data.waistSize, data.hipSize],
    material: data.material?.trim(),
    name: data.name?.trim(),
    neckline: data.neckline?.trim(),
    photos: data.photos.filter((photo) => !!photo),
    price: Number(data.price.replace(/\D/ig, '')),
    salePrice: Number(data.salePrice?.replace(/\D/ig, '')),
    silhouette: data.silhouette?.trim(),
    sleeve: data.sleeve?.trim(),
    streetSize: data.streetSize?.trim(),
    tagSize: data.tagSize?.trim(),
    yearPurchased: data.yearPurchased?.toString()?.trim(),
  };
  return makeRequest(API_URL + 'seller/listing', payload).send();
}

export function publishListings(listings) {
  const payload = { listings };
  return makeRequest(API_URL + 'seller/publish', payload).send();
}