import { makeRequest, API_URL } from '../Fetch';
import * as firebaseHelpers from '../Firebase';

export function submitQuestion(itemId, question) {
  const url = `${API_URL}listing/${itemId}/question`;
  const payload = { question };
  return makeRequest(url, payload).send();
}

export async function updateOffer(itemId, offerId, status) {
  const url = `${API_URL}listing/${itemId}/offer/${offerId}`;
  const payload = { status };
  await makeRequest(url, payload).setMethod('PUT').send();
}

export async function makeOffer(itemId, _amount, destinationAccountUID, accountType) {
  const amount = _amount.replace(',', '');
  const url = `${API_URL}listing/${itemId}/offer`;
  const payload = { amount };
  const offerId = await makeRequest(url, payload).send();
  const message = _amount;
  await sendMessage(destinationAccountUID, accountType, message, 'offer', offerId, itemId);
}

export function answerQuestion(itemId, questionId, answer) {
  const url = `${API_URL}listing/${itemId}/question/${questionId}`;
  const payload = { answer };
  return makeRequest(url, payload).send();
}

export function sendMessage(destinationAccountUID, accountType, message, messageType, id, listing) {
  const userPath = accountType === 2 ? 'seller' : 'user';
  const url = `${API_URL}${userPath}/${destinationAccountUID}/message`;
  const payload = {
    message, type: messageType, id, listing,
  };
  return makeRequest(url, payload).send();
}

export function updatePassword(accountType, newPassword) {
  const userPath = accountType === 2 ? 'seller' : 'user';
  const payload = { password: newPassword };
  return makeRequest(`${API_URL}${userPath}`, payload)
    .setMethod('PUT')
    .send();
}

export function updatePackage(packageType) {
  const url = `${API_URL}seller/package`;
  return makeRequest(url, { packageType }).setMethod('PUT').send();
}

export function getAccountType() {
  const url = `${API_URL}users/type`;
  return makeRequest(url).send();
}

export async function createUser(form) {
  const payload = {
    profile: {
      firstName: form.firstName?.trim(),
      lastName: form.lastName?.trim(),
      email: form.email?.trim(),
      phoneNumber: form.phoneNumber?.trim(),
    },
    password: form.password,
    address: {
      street1: form.street1?.trim(),
      street2: form.street2?.trim(),
      city: form.city?.trim(),
      state: form.state?.trim(),
      zip: form.zip?.trim(),
      country: 'US',
    },
  };
  const loginToken = await makeRequest(API_URL + 'user', payload)
    .setNoAuth()
    .send();
  await firebaseHelpers.signInWithToken(loginToken);
}