import * as types from '../actions/actionTypes';

const initialState = {
  showModal: false,
  modalComponent: null,
  modalOptions: {
    onModalHide: () => {},
    showClose: true,
    disableBackdropClick: false,
    fullscreen: false,
  },
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.SHOW_MODAL: {
    return {
      ...state,
      modalOptions: {
        ...state.modalOptions,
        ...action.modalOptions,
      },
      showModal: action.showModal,
      modalComponent: action.modalComponent,
    };
  }
  default:
    return state;
  }
};
