import { makeRequest, API_URL } from '../Fetch';

export async function getShippingCostFromAddress(address, name, email, phoneNumber) {
  try {
    const payload = {
      address,
      name,
      email,
      phoneNumber,
    };
    const res = await makeRequest(API_URL + 'shipping/rate', payload)
      .setNoAuth()
      .send();

    if (res.length) {
      return res.sort((a, b) => b.delivery_days - a.delivery_days)[0];
    } else {
      return {};
    }
  } catch (e) {
    console.error(e);
  }
}