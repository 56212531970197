export default function (data, isBoutique) {
  const [year, month, day] = data.dob?.split('-');
  const payload = {
    password: data.password,
    profile: {
      email: data.email?.trim(),
      firstName: data.firstName?.trim(),
      lastName: data.lastName?.trim(),
      phoneNumber: data.phoneNumber?.trim(),
      dob: [month, day, year].join('/'),
      avatar: data.avatar,
    },
    address: {
      street1: data.street1?.trim(),
      street2: data.street2?.trim(),
      state: data.state?.trim(),
      city: data.city?.trim(),
      country: data.country || 'US',
      zip: data.zip?.trim(),
    },
    couponCode: data.couponCode?.trim(),
    packageType: data.package || 0,
    bankAccount:{
      accountNumber: data.accountNumber?.replace(/\D/ig, '')?.trim(),
      routingNumber: data.routingNumber?.replace(/\D/ig, '')?.trim(),
    },
  };

  if (data.paypalID) {
    payload.paypalID = data.paypalID;
  } else {
    payload.stripeToken = data.stripeToken;
  }

  if (isBoutique) {
    payload.details = {
      position: data.position,
      ein: data.ein?.trim(),
      website: data.website?.trim(),
      phoneNumber: data.boutiquePhoneNumber?.trim(),
      name: data.boutiqueName?.trim(),
    };
    payload.profile.title = data.position?.trim();
  } else {
    payload.listing = {
      alterations: data.alterations?.trim(),
      color: data.color?.trim(),
      condition: data.condition?.trim(),
      story: data.story?.trim(),
      history: data.history?.trim(),
      receipt: data.receipt?.trim(),
      designer: data.designer,
      dimensions: [data.bustSize, data.waistSize, data.hipSize],
      material: data.material?.trim(),
      name: data.name?.trim(),
      neckline: data.neckline?.trim(),
      photos: data.photos,
      price: data.price.replace(/\D/ig, ''),
      salePrice: data.salePrice?.replace(/\D/ig, ''),
      silhouette: data.silhouette?.trim(),
      sleeve: data.sleeve?.trim(),
      streetSize: data.streetSize?.trim(),
      tagSize: data.tagSize?.trim(),
      yearPurchased: data.yearPurchased?.toString()?.trim(),
    };
  }
  return payload;
}