import React from 'react';
import Row from '../Row';
import Button1 from '../Button1';


function OfferResponse({
  acceptOffer, declineOffer, listing, id,
}) {
  return (
    <Row style={{ width: '50%' , marginTop: 20 }}>
      <Button1 onClick={() => acceptOffer(listing, id)} style={{ width:100, height: 44 }} color="#7DBEC8">ACCEPT</Button1>
      <Button1 onClick={() => declineOffer(listing, id)} style={{ width:100, height: 44 }} color="#7DBEC8">DENY</Button1>
    </Row>
  );
}

export default OfferResponse;
