export { default as wrapRootElement } from './wrap-with-provider.js';
export { default as onClientEntry } from './on-initial-client-render.js';
const { registerLinkResolver } = require('@prismicio/gatsby-source-prismic-graphql');

export const onRouteUpdate = () => {
  window.scrollTo(0, 0);
};

const linkResolver = function (post) {
  return `/${post.uid || ''}`;
};

registerLinkResolver(linkResolver);