import React from 'react';
import { Container } from './styled.js';

function Button1({
  children, color = '#EAAFB7', disabled, ...other
}) {
  return (
    <Container disabled={disabled} color={color} {...other}>
      {children}
    </Container>
  );
}

export default Button1;