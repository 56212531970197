/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-undef */
import React from 'react';
import { getDocument } from '../helpers/Firebase';
import OfferResponse from '../components/OfferResponse';
import PurchaseFromOffer from '../components/PurchaseFromOffer';
import * as types from './actionTypes';
import { setLoading } from './loadingActions';
import { acceptOffer, declineOffer } from './userActions';
import { addItemToCart } from './checkoutActions';

export function loadMessages(uid) {
  return async (dispatch, getState) => {
    const {
      accountData: { messages } = [], accountType, offers,
    } = getState().userReducer;
    const { products } = getState().shopReducer;
    const messagesFormatted = await formatMessages(messages, uid, accountType, offers, products, dispatch);

    try {
      dispatch(setLoading(true));
      dispatch({
        type: types.LOAD_MESSAGES,
        messages: messagesFormatted,
      });
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

function formatMessages(messages = [], uid, accountType, offers, products, dispatch) {
  return new Promise((resolveA) => {
    const result = {};
    const promises = messages.map(({
      from, date, message, id, to, accountType: otherUserAccountType, type = 'message', listing,
    }) =>
      new Promise((resolveB) => {
        let url;
        const otherUserUid = from === uid ? to : from;

        if (otherUserAccountType === 2) {
          //account is not a user type
          url = `users/${otherUserUid}`;
        } else if (otherUserAccountType === 1 || otherUserAccountType === 0) {
          url = `sellers/${otherUserUid}`;
        }
        getDocument(url).then(({
          profile: {
            avatar, firstName, lastName,
          },
        }) => {
          let component;

          if (type ==='offer' && (accountType === 0 || accountType === 1)) {
            const offer = offers.find(({ id: _id }) => id == _id);

            if (offer && offer.status === 'pending') {
              component = <OfferResponse acceptOffer={() => dispatch(acceptOffer(listing, id))} declineOffer={() => dispatch(declineOffer(listing, id))} listing={listing} id={id} />;
            }
          }

          if (type === 'offer-accept' && accountType === 2) {
            const product = Object.values(products).find(({ __id }) => __id === listing);

            if (product && product.sold === false) {
              component = <PurchaseFromOffer addItemToCart={() => dispatch(addItemToCart(listing))} listing={listing} id={id} />;
            }
          }

          let _to = to;
          let _from = from;
          let name = `${firstName} ${lastName}`;

          if (type.match(/offer/ig)) {
            const offer = offers.find(({ id: _id }) => id == _id);

            if (offer && offer?.listing) {
              _from = `${from}-${offer.listing}`;
              _to = `${to}-${offer.listing}`;
              name = `Listing Offer: ${listing} - ${name}`;
            }
          }

          if (_from.match(uid) && !result[_to]) {
            result[_to] = {
              avatar,
              name,
              uid: to,
              thread: [],
              threadMessages: '',
            };
          } else if (_to.match(uid) && !result[_from]) {
            result[_from] = {
              avatar,
              name,
              uid: from,
              thread: [],
              threadMessages: '',
            };
          }

          if (_from.match(uid)) {
            //current user sent the message
            result[_to].threadMessages += message + ' ';
            result[_to].thread.push({
              date, message, id, from, to, type, listing, component,
            });
          } else {
            //current user received the message
            result[_from].threadMessages += message + ' ';
            result[_from].thread.push({
              date, message, id, from, to, type, listing, component,
            });
          }
          resolveB();
        }).catch(alert);
      }),
    );

    Promise.all(promises).then(() => {
      resolveA(result);
    });
  });
}