import React from 'react';
import { connect } from 'react-redux';
import { formatAmount } from '../../helpers/Format';
import Button1 from '../Button1';
import { addItemToCart } from '../../actions/checkoutActions';
import {
  Container, Divider,
  ProfilePicture, NotificationMessage,
  Message,
} from './styled.js';

function OfferNotificationUser({
  addItemToCart, avatar,
  amount, firstName, lastName, listing,
  id,
}) {
  return (
    <Container>
      <ProfilePicture>
        <img src={avatar} />
        <div>
          <div>{firstName} {lastName}</div>
          <div>Accepted your offer</div>
        </div>
      </ProfilePicture>
      <NotificationMessage>
        <Message>${formatAmount(amount * 100)}</Message>
      </NotificationMessage>
      <Divider />
      <Button1 onClick={() => addItemToCart(listing, id)} style={{ height: 44, marginTop: 20 }} color="#7DBEC8">PURCHASE DRESS</Button1>
    </Container>
  );
}

const mapDispatchToProps = { addItemToCart };

export default connect(null, mapDispatchToProps)(OfferNotificationUser);