import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const Body = styled.div`
  width: 45.63vw;
  height: 27.00vw;
  margin-left: 2.125vw;
  margin-top: 1.250vw;
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
  border-radius: 0.2500vw;
  display: flex;
  flex-direction: column;
`;

export const BodyHeading = styled.div`
  margin-top: 1.438vw;
  font-family: sofia-pro, sans-serif; 
  font-weight: 400;
  font-weight: bold;
  font-size: 1.125vw;
  color: #3E3F42;
  line-height: 28px;
  margin-left: 1.875vw;
`;

export const BodySubHeading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.8750vw;
  color: #9EA0A5;
  line-height: 22px;
  margin-top: 0.2500vw;
  margin-left: 1.875vw;
  margin-bottom: 1.500vw;
`;

export const Divider = styled.div`
  background-color: #EAEDF3;
  width: 100%;
  height: 1px;
`;

export const ProfilePicture = styled.div`
  margin-left: 2.500vw;
  margin-top: 1.688vw;
  display: flex;
  img {
    width: 3.750vw;
    height: 3.750vw;
    border-radius: calc(3.750vw / 2);
  }
  > div {
    display: flex;
    flex-direction: column;
    margin-left: 1.250vw;
    > div:nth-child(1) {
      font-family: sofia-pro, sans-serif;
  font-weight: 700;
      font-size: 1.000vw;
      color: #3E3F42;
      line-height: 24px;
    }
    > div:nth-child(2) {
      font-family: sofia-pro, sans-serif;
  font-weight: 700;
      font-size: 0.8750vw;
      color: #9EA0A5;
      line-height: 22px;
    }
  }
`;

export const NotificationMessage = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 7.938vw;
  margin-bottom: 1.250vw;
`;
export const Message = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 1.125vw;
  color: #464855;
  margin-bottom: 1.063vw;
`;

export const ResponseInputContainer = styled.div`
  width: 34.94vw !important;
  height: 5.750vw;
  background: #F7F7F7;
  border: 1px solid #979797;
  border-radius: 0.6875vw;
`;
export const ResponseInput = styled.textarea`
  width: calc(100% - (1.063vw * 2));
  height: calc(100% - (1.063vw * 2));
  background-color: transparent;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.8750vw;
  color: #464855;
  margin: 1.063vw;
`;

export const Button = styled.div`
  width: 17.31vw;
  height: 2.375vw;
  background-color: #FE95A2;
  border-radius: 0.2500vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.8750vw;
  color: #FFFFFF;
  text-align: center;
  margin-top: 1.250vw;
  margin-right: 3.250vw;
  align-self: flex-end;
  cursor: pointer;
`;